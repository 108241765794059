.overlayFloatingButton {
  position: fixed !important;
  bottom: 30px !important;
  right: 30px !important;
  background-color: var(--secondary-color) !important;
  color: white !important;
  border: none !important;
  border-radius: 50% !important;
  width: 50px !important;
  height: 50px !important;
  font-size: 24px !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2) ;
  animation: pulse-animation 3s infinite; /* Apply pulse animation */
}

@keyframes pulse-animation {
  0% {
    box-shadow: 
      0 0 0 0 var(--primary-color),                   /* Base color */
      0 0 0 5px rgba(5, 210, 89, 0.5),               /* Lighter shade (50% opacity) */
      0 0 0 10px rgba(4, 168, 71, 0.25),             /* Slightly darker shade (25% opacity) */
      0 0 0 15px rgba(3, 126, 53, 0.05);             /* Darker shade (faint, 5% opacity) */
  }
  70% {
    box-shadow: 
      0 0 0 20px rgba(5, 210, 89, 0.8),              /* Brighter primary color */
      0 0 0 25px rgba(4, 168, 71, 0.5),              /* Lighter shade */
      0 0 0 30px rgba(3, 126, 53, 0.25),             /* Darker shade */
      0 0 0 35px rgba(2, 84, 35, 0.1);               /* Faint darker layer */
  }
  100% {
    box-shadow: 
      0 0 0 0 rgba(5, 210, 89, 1),                   /* Base color */
      0 0 0 5px rgba(4, 168, 71, 0.5),              /* Lighter shade */
      0 0 0 10px rgba(3, 126, 53, 0.25),            /* Darker shade */
      0 0 0 15px rgba(2, 84, 35, 0.05);             /* Faint darker layer */
  }
}
