* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Toastify__toast-container .Toastify__toast-icon svg {
  fill: white; /* Change icon color */
}

:root {
  --primary-color: #05d259; 
  --primary-text-color: #FFFFFF; 
  --hover-primary-color: #FFFFFF; 
  --hover-primary-text-color: #05d259; 
  --hover-primary-border-color: #05d259; 
  
  --secondary-color: #f54538; 
  --secondary-text-color: #FFFFFF; 
  --hover-secondary-color: #FFFFFF; 
  --hover-secondary-text-color: #f54538; 
  --hover-secondary-border-color: #f54538; 

  --button-corner-radius: 45px;
  --hover-button-width: 1px;
  --border-shadow: 0px 0px 17px 0px rgb(0 0 0 / 23%);

  --background-color-other-section: #E3E5E8; 


  --body-text-color: #2e3e48; 
}

.overlay-btn-primary {
  background-color: var(--primary-color) !important;
  color: var(--primary-text-color) !important;
  border: solid;
  border-color: var(--primary-color) !important;
  border-radius: var(--button-corner-radius) !important;
  box-shadow: var(--border-shadow) !important;
}

.btn-overlay-primary {
  background-color: var(--primary-color) !important;
  color: var(--primary-text-color) !important;
  border: solid;
  border-color: var(--primary-color) !important;
  border-radius: var(--button-corner-radius) !important;
  box-shadow: var(--border-shadow) !important;
  font-size: 14px !important;
  padding: 5px !important;
}

.overlay-btn-primary:hover {
  background-color: var(--hover-primary-color) !important;
  color: var(--hover-primary-text-color) !important;
  border-color: var(--hover-primary-border-color) !important;
  border-width: var(--hover-border-width) !important;
}

.overlay-btn-secondary {
  background-color: var(--secondary-color) !important;
  color: var(--secondary-text-color) !important;
  border: solid;
  border-color: var(--secondary-color) !important;
  border-radius: var(--button-corner-radius) !important;
  box-shadow: var(--border-shadow) !important;
  
}

.overlay-btn-secondary:hover {
  background-color: var(--hover-secondary-color) !important;
  color: var(--hover-secondary-text-color) !important;
  border-color: var(--hover-secondary-border-color)!important;
  border-width: var(--hover-border-width)!important;
}

@media (max-width: 1024px) {
  .overlay-btn-primary,
  .overlay-btn-secondary {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .overlay-btn-primary,
  .overlay-btn-secondary {
    font-size: 14px; 
  }
}