.overlayChatbotWrapper {
  position: fixed !important;
  bottom: 20px !important;
  right: 20px !important;
  width: 500px !important;
  height: calc(58% * 4 / 3) !important; /* Keeps a 3:4 aspect ratio */
  background: #fff !important;
  border-radius: 10px !important;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3) !important;
  overflow: hidden !important;
  z-index: 1000 !important;
  transition: all 0.3s ease !important;
}

/* Fullscreen mode */
.overlayChatbotWrapper.fullscreen {
  width: 60% !important;
  height: calc(68% * 4 / 3) !important; /* Keeps a 3:4 aspect ratio */
  bottom: 20px !important;
  right: 20px !important;
  border-radius: 10px !important;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3) !important;
  overflow: hidden !important;
  z-index: 1000 !important;
  transition: all 0.3s ease !important;
}


.overlayChatbotHeader {
  padding: 10px !important;
  background-color: var(--primary-color) !important;
  color: var(--primary-text-color) !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin-bottom: 1rem !important;
}

.overlayChatbotBody {
  padding: 10px !important;
  height: calc(100% - 60px) !important;
  overflow-y: auto !important; 
  justify-content: center !important; /* Centers vertically */
  align-items: center !important; /* Centers horizontally */ 
}

.overlayMessageSection {
  flex-grow: 1 !important;
  /* Makes the message section take up remaining space */
  overflow-y: auto !important;
  overflow-x: hidden !important;
  /* Hide horizontal scrolling */
  padding-bottom: 10px !important;
  word-wrap: break-word !important;
  /* Ensures long words break to the next line */
  word-break: break-word !important;
  /* Prevents overflow by breaking long words */
}


.overlayCloseButton,
.overlayEnlargeButton {
  background: transparent !important;
  border: none !important;
  color: var(--primary-text-color) !important;
  font-size: 18px !important;
  cursor: pointer !important;
  padding: 0px 5px 0px 5px !important;
}


.overlayChatMainFrame {
  background-color: #044557 !important;
  display: flex !important;
  flex-direction: row !important;
  position: relative !important;
  min-height: 1000px !important;
}

.overlayDsDropdown,
.overlayMsDropdown {
  background-color: var(--secondary-color) !important;
  height: 40px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  padding: 0 !important;
  width: 50px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.overlayDsDropdown {
  margin-left: 5px !important;

}

.overlayMsDropdown {
  margin-right: 5px !important;
}

.overlayMsDropdown svg {
  width: 32px !important;
  height: 32px !important;
}


.overlayChatBox {
  display: flex !important;
  align-items: center !important;
  background-color: var(--background-color-other-section) !important;
  border-radius: 8px !important;
  padding: 8px !important;
  gap: 8px !important;
  border: none !important;
}

.overlayChatBoxWithText {
  background-color:  var(--background-color-other-section) !important;
  border-radius: 8px !important;
  gap: 8px !important;
  padding: 8px !important;
  border: none !important;
}

.overlayChatInput {
  background-color: var(--background-color-other-section) !important;
  border: none !important;
  width: 100% !important;
  max-height: 80px !important;
  font-size: 14px !important;
  overflow-y: auto !important;
  resize: none !important;
  min-height: 20px !important;
  border-radius: 0px !important;
}

.overlayChatInput::placeholder {
  font-size: 14px !important;
  color: black !important;
}

.overlayChatInput:focus {
  border: none !important;
  outline: none !important;
}


.overlaySendBtn {
  width: 40px !important;
  height: 40px !important;
  font-weight: 500 !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 5px !important;
  font-size: 18px !important;
}

.overlaySendBtn:disabled {
  background-color: rgba(13, 54, 70, 0.5) !important;
}

.overlay-you-icon {
  background-color: black !important;
  height: 30px !important;
  width: 30px !important;
  border-radius: 50% !important;
}

.overlay-bot-icon {
  background-color: #fcac34 !important;
  height: 30px !important;
  width: 30px !important;
  border-radius: 50% !important;
}

.overlayUserDiv {
  display: flex !important;
  flex-direction: row !important;
  justify-content: end !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.overlayUser {
  display: inline-block !important;
  background-color: var(--background-color-other-section) !important;
  border-radius: 8px !important;
  margin-bottom: 20px !important;
  padding: 15px 10px !important;
  margin-top: 20px !important;
  max-width: 700px !important;
  font-size: 14px !important;
}

.overlayAssistant {
  font-size: 14px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  background-color: #ffffff
}

.overlayTime {
  color: silver !important;
  font-size: 14px !important;
  font-weight: normal !important;
}

.overlayIcons {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

.overlayClickable {
  font-size: 14px !important;
  cursor: pointer !important;
}

.overlayDropdown:focus {
  outline: none !important;
}

.overlayLandingSection {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  overflow-y: auto !important;
  align-self: start !important;
  margin-bottom: 80px !important;
}

.overlayLandingText {
  font-weight: bold !important;
  font-size: 14px !important;
}

.overlayLandingPara {
  margin-top: 10px !important;
  color: black !important;
  display: flex !important;
  align-items: flex-start !important;
}

.overlayLandingPara a {
  color: black !important;
}

.overlayMultiSelect {
  background-color: var(--background-color-other-section) !important;
}

.overlayBtnResetOptions {
  border-radius: 8px !important;
  background: #f0f0f0 !important;
  width: 80% !important;
  font-size: 0.9rem !important;
}

.overlay-btn-reset-options:hover {
  background-color: #e4e4e4 !important;
}

.checkGroup {
  align-items: center !important;
}

.dropdownItem {
  font-size: 14px !important;
}

.dropdownItem:active {
  background-color: transparent !important;
  color: black !important;
}

.dropdownMenu {
  margin: 0px !important;
  transform: translate3d(12.7778px, 112.778px, 0px) !important;
  padding: 2px !important;
}

ul.dropdownMenu li {
  margin-bottom: 0px !important;
}

ul.dropdownMenu li {
  margin-bottom: 0px !important;
}

.formCheckInput{
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #dee2e6;
  border: var(--bs-border-width) solid var(--bs-border-color);
  flex-shrink: 0;
  height: 1em;
  margin-top: .25em;
  vertical-align: top;
  width: 1em;
}

.formCheckInput:checked {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.dropdownItem:hover {
  background-color: hsl(0, 0%, 93%) !important;
}

.overlayUploadBtn {
  width: 80% !important;
  font-size: 16px !important;
}

.overlayLinkText {
  color: #323232 !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  text-decoration: none !important;
}

.overlayLineSeparator {
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  margin: 20px 0 15px !important;
}

.overlayLine {
  flex: 1 !important;
  border: 1px solid #ccc !important;
}

.overlayOrText {
  background-color: #fff !important;
  padding: 0 15px !important;
  color: #9f9f9f !important;
}

.overlayProgressBar {
  position: absolute !important;
  bottom: 1px !important;
  width: 99% !important;
  margin: auto !important;
}

.overlayLawLinks {
  background-color: var(--primary-color) !important;
  border: none !important;
  border-radius: 3px !important;
  width: 16px !important;
  height: 16px !important;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
  position: relative !important;
  top: 5px !important;
}

.overlayWhatsLegalIcon {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 5px !important;
  background-color: #044557 !important;
}

.overlayDisclaimer {
  color: #323232 !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  font-weight: bold !important;
  border: 1px solid #044557 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  margin: 20px 12px 80px 0px !important;
}

.overlayRecommendationText {
  color: #044557 !important;
  font-size: calc(13px - 0.1vw) !important;
  margin-top: 10px !important;
}

.overlayRecommendationText a {
  color: #044557 !important;
}

.overlayDivider {
  border-bottom: 1px solid black !important;
  height: 10px !important;
  background: #044557 !important;
}



@keyframes blink {
  0% {
    border-color: transparent !important;
  }

  50% {
    border-color: red !important;
  }

  100% {
    border-color: transparent !important;
  }
}

.overlayErrorAgbs {
  animation: blink 1s infinite !important;
}

.overlayErrorLabelAgbs {
  animation: blink 1s infinite !important;
  color: red !important;
}

.overlayErrorDisclaimerChecked {
  animation: blink 1s infinite !important;
}

.overlayErrorLabelDisclaimerChecked {
  animation: blink 1s infinite !important;
  color: red !important;
}

.overlayContentWrapper {
  max-width: 700px !important;
}

.overlayQuestionsContainer {
  margin-top: 5px !important;
  display: flex !important;
  flex-direction: column !important;
  width: 90% !important;
  max-width: 550px !important;
  gap: 10px !important;
  padding: 5px !important;
  box-sizing: border-box !important;
  list-style: disc !important;
  padding-left: 20px !important;
}

.overlayQuestionButton {
  background-color: var(--background-color-other-section) !important;
  color: black !important;
  flex: 1 !important;
  border: none !important;
  cursor: pointer !important;
  font-size: 14px !important;
  text-align: left !important;
  box-sizing: border-box !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  padding: 8px !important;
  border-radius: 5px !important;
  margin-left: -20px !important;
  list-style: none !important;
}

.overlayQuestionsContainer .questionButton::before {
  content: '\2022' !important;
  /* Unicode for bullet point */
  color: black !important;
  /* Color of the bullet point */
  font-weight: bold !important;
  display: inline-block !important;
  width: 1em !important;
  margin-left: 5px !important;
  margin-right: 10px !important;
  /* Space between bullet and text */
}

.overlayQuestionButton:hover {
  background-color: var(--background-color-other-section) !important;
}

.overlayQuestionButton:active,
.overlayQuestionButton:focus {
  background-color: var(--background-color-other-section) !important;
}

.overlaySmallParagraph {
  font-size: 10px !important;
  color: rgba(13, 54, 70, 0.5) !important;
}

.overlayDropdownText {
  font-size: 14px !important;
}

.overlayWebsiteLogo {
  height: 22px !important;
}

.overlaySendButtonSvg,
.overlayLawIconSvg  {
  min-width: 16px !important; /* Ensures minimum size */
  min-height: 16px !important;
  max-width: 100% !important; /* Allows responsive scaling */
  max-height: 100% !important;
}

.overlayCaptainFrankHelp{
  font-weight: bold !important;
  font-size: 14px !important;
}

.overlaySpinerGrow {
  color: var(--primary-color)  !important;
}

.overlayCaptainFrankIcon {
  height: 40px !important;
  width: 40px !important;
}


@media (max-width: 480px) {
  .overlayChatbotWrapper {
    height: calc(56% * 4 / 3) !important; /* Keeps a 3:4 aspect ratio */
    width: 300px !important;
    bottom: 15px !important;
    right: 15px !important;
  }

  .overlayCloseButton,
  .overlayEnlargeButton {
    font-size: 16px !important; /* Slightly smaller font for smaller screens */
    padding: 10px !important; /* Increases padding for easier touch access */
  }

  .overlaySendBtn {
    width: 36px !important; /* Reduced width for smaller screens */
    height: 36px !important;
    font-size: 16px !important; /* Smaller font size */
    margin-right: 3px !important; /* Smaller margin for better fit */
  }

  .overlaySendButtonSvg,
  .overlayLawButtonSvg {
    min-width: 14px !important; /* Slightly smaller icon for mobile */
    min-height: 14px !important;
  }

  .overlayDsDropdown,
  .overlayMsDropdown {
    height: 36px !important; /* Slightly reduced height for smaller screens */
    width: 45px !important; /* Reduced width */
    font-size: 14px !important; /* Smaller font size for better readability on mobile */
  }

  .overlayChatInput {
    font-size: 14px !important;
    padding: 8px !important;
    max-height: 60px !important;
    width: 95% !important;
  }

  .overlayChatInput::placeholder {
    font-size: 14px !important;
  }

  .overlayChatBox,
  .overlayChatBoxWithText {
    padding: 6px !important;
    gap: 6px !important;
    border-radius: 6px !important;
  }

  .overlayChatbotWrapper.fullscreen {
    width: 90% !important;
  }
}
@media (max-width: 750px) {
  .overlayChatbotWrapper {
    bottom: 15px !important;
    right: 15px !important;
  }
  
 .overlayChatbotWrapper.fullscreen {
    bottom: 15px !important;
    right: 15px !important;
    width: 90% !important;
  }
}

/* Focus state styling */
.overlayChatInput:focus {
  background-color: var(--background-color-other-section) !important;
  outline: none !important; /* Removes any border outline */
  box-shadow: none !important; /* Ensures no shadow is applied */
}



@media (max-width: 800px) and (orientation: landscape) {
  .overlayChatbotWrapper {
    height: calc(60% * 4 / 3) !important; /* Keeps a 3:4 aspect ratio */
    width: 60% !important;
    border-radius: 10px !important;
  }

  .overlayCloseButton,
  .overlayEnlargeButton {
    font-size: 16px !important; /* Slightly smaller font for smaller screens */
    padding: 10px !important; /* Increases padding for easier touch access */
  }

  .overlaySendBtn {
    width: 36px !important; /* Reduced width for smaller screens */
    height: 36px !important;
    font-size: 16px !important; /* Smaller font size */
    margin-right: 3px !important; /* Smaller margin for better fit */
  }

  .overlaySendButtonSvg,
  .overlayLawButtonSvg {
    min-width: 14px !important; /* Slightly smaller icon for mobile */
    min-height: 14px !important;
  }

  .overlayDsDropdown,
  .overlayMsDropdown {
    height: 36px !important; /* Slightly reduced height for smaller screens */
    width: 45px !important; /* Reduced width */
    font-size: 14px !important; /* Smaller font size for better readability on mobile */
  }

  .overlayChatInput {
    font-size: 14px !important;
    padding: 8px !important;
    max-height: 60px !important;
    width: 95% !important;
  }

  .overlayChatInput::placeholder {
    font-size: 14px !important;
  }

  .overlayChatBox,
  .overlayChatBoxWithText {
    padding: 6px !important;
    gap: 6px !important;
    border-radius: 6px !important;
  }
}
